import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { AnalyticsActivateModal } from "../components/analytics/AnalyticsActivateModal/AnalyticsActivateModal"
import { MobileNavbar } from "../components/general/MobileNavbar/MobileNavbar"
import { Navbar } from "../components/general/Navbar/Navbar"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Protected } from "../components/general/Protected/Protected"
import { Seo } from "../components/general/Seo/Seo"
import { Footer } from "../components/marketing/Footer"
import { OpportunitiesPaywall } from "../components/opportunities/OpportunitiesPaywall/OpportunitiesPaywall"
import { PremiumModal } from "../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"
import { Container } from "../components/UI/Container"

export const OpportunitiesRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/opportunities/title`,
        })}
        description={intl.formatMessage({
          id: `seo/opportunities/description`,
        })}
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/opportunities",
            isDefault: true,
          },
        ]}
      />

      <div className="relative min-h-screen">
        <Navbar />

        <Container>
          <OpportunitiesPaywall />
        </Container>
      </div>

      <AnalyticsActivateModal />
      <MobileNavbar />
      <PremiumModal />
      <Footer />
      <Notifications />
    </Protected>
  )
}
